import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/runner/_work/designsystem-docs/designsystem-docs/src/templates/MDXPage.jsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DSExample = makeShortcode("DSExample");
const UUTestReport = makeShortcode("UUTestReport");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Bruk av typografi`}</h2>
    <p>{`Beskrivelse av SpareBank 1-skriftypene og eksempel på skriftstørrelser er beskrevet under `}<a parentName="p" {...{
        "href": "/profil/typografi/"
      }}>{`Profil`}</a>{`. Denne siden handler om de ulike typografi-komponentene som utviklere kan ta i bruk.`}</p>
    <h2>{`Heading`}</h2>
    <p>{`Heading levels er først og fremst skapt for organisering av innhold, ikke visualisering.
Dette betyr at overskriftshierarkiet må opprettholdes som første prioritet. Headingene i seg selv kan styles for å følge den visuelle designmalen, men rekkefølgen på overskriftene er av absolutt største viktighet.`}</p>
    <p>{`Heading 1 er kapitteloverskriften, om du vil, mens de påfølgende under-headingene er oppdeling av budskapet slik det relaterer til foregående heading levels.`}</p>
    <p>{`En Heading 2 kan ikke komme uten at en heading 1 kommer først, siden Heading 2 betyr at avsnittet er relevant for Heading 1, men fordrer presisering visuelt og strukturelt.
På samme måte er Heading 3 underlagt Heading 2. Heading 3 i dette tilfellet vil da være en under-kategori for Heading 2, og kan ikke implementeres uten å «innlemmes» under en relevant heading 2.`}</p>
    <p>{`Man kan ha flere headingnivåer av samme nivå etter hverandre. Det eneste dette betyr er at de presenterer en ny paragraf eller innhold som er relevant for den overordnede headingen, men ikke passer som en underparagraf for den tidligere headingen av samme nivå.`}</p>
    <p><strong parentName="p">{`Merk: For å sikre at SpareBank1-fonten settes som default på tekst også utenom disse komponentene må du passe på å ha klassen `}<code parentName="strong" {...{
          "className": "language-text"
        }}>{`.ffe-body`}</code>{` på eller nær `}<code parentName="strong" {...{
          "className": "language-text"
        }}>{`body`}</code>{`-elementet.`}</strong></p>
    <DSExample name="typography_Heading" mdxType="DSExample" />
    <UUTestReport reportID="Typography_Heading" mdxType="UUTestReport" />
    <h2>{`EmphasizedText`}</h2>
    <p>{`Kursiv tekst som brukes der vi vil utheve et ord eller en setning i en lengre tekst.`}</p>
    <DSExample name="typography_EmphasizedText" mdxType="DSExample" />
    <UUTestReport reportID="Typography_EmphasizedText" mdxType="UUTestReport" />
    <h2>{`StrongText`}</h2>
    <p>{`Bold tekst som brukes når vi vil utheve noe i en lengre tekst.`}</p>
    <DSExample name="typography_StrongText" mdxType="DSExample" />
    <UUTestReport reportID="Typography_StrongText" mdxType="UUTestReport" />
    <h2>{`LinkText`}</h2>
    <p>{`Lenker vises vanligvis med en understrek, siden dette er en standard i nettlesere, og gjør det ekstra tydelig for brukerne at teksten er klikkbar. `}</p>
    <DSExample name="typography_LinkText" mdxType="DSExample" />
    <UUTestReport reportID="Typography_LinkText" mdxType="UUTestReport" />
    <p>{`Dersom det er tydelig ut fra sammenhengen at en tekst er en lenke eller lenken
består av et ikon uten synlig tekst kan man vurdere å skru av understreken.
Husk at farge alene ikke er nok til å indikere at en tekst er klikkbar. Ikke
alle våre brukere kan skille mellom fargen på lenkene og fargen på brødteksten.`}</p>
    <h2>{`LinkIcon`}</h2>
    <p>{`For lenker som kun består av et ikon uten synlig tekst.`}</p>
    <DSExample name="typography_LinkIcon" mdxType="DSExample" />
    <UUTestReport reportID="Typography_LinkIcon" mdxType="UUTestReport" />
    <h2>{`Paragraph`}</h2>
    <p>{`Et vanlig tekstavsnitt`}</p>
    <DSExample name="typography_Paragraph" mdxType="DSExample" />
    <UUTestReport reportID="Typography_Paragraph" mdxType="UUTestReport" />
    <h2>{`SmallText`}</h2>
    <p>{`En tekst som skal være mindre enn den vanlige skriftstørrelsen.`}</p>
    <DSExample name="typography_SmallText" mdxType="DSExample" />
    <UUTestReport reportID="Typography_SmallText" mdxType="UUTestReport" />
    <h2>{`MicroText`}</h2>
    <p>{`Den minste skriftstørrelsen vi har.`}</p>
    <DSExample name="typography_MicroText" mdxType="DSExample" />
    <UUTestReport reportID="Typography_MicroText" mdxType="UUTestReport" />
    <h2>{`PreformattedText`}</h2>
    <p>{`Kan brukes hvis du har tekst som allerede er formattert og du vil vise teksten sånn.`}</p>
    <DSExample name="typography_PreformattedText" mdxType="DSExample" />
    <UUTestReport reportID="Typography_PreformattedText" mdxType="UUTestReport" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      